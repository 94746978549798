import React from "react"

import Select, { Props as SelectProps, Styles } from "react-select"

import { isFn } from "~/src/lib/any"
import { getVar } from "~/src/lib/style"

// Typography
const fontSize = "13.6px"

export type FallDownSelectOption = {
  label: string
  value: string
}

export interface FallDownProps extends SelectProps<FallDownSelectOption> {}

export function FallDown(props: FallDownProps) {
  const { noBorder = false, styles = {}, ...restProps } = props

  const thisStyles: Styles<FallDownSelectOption, false> = {
    control(given, state) {
      const { menuIsOpen, isFocused } = state

      return {
        ...given,
        "&:hover": {
          cursor: "text",
          borderColor: getVar("primaryColor"),
          color: getVar("primaryColor"),
        },
        boxShadow: "none",
        borderColor: menuIsOpen || isFocused ? getVar("primaryHighlightColor") : getVar("textHighlightColor"),
        border: noBorder ? 0 : "1px solid !important",
        fontSize,
        minHeight: "44px",
      }
    },
    menu(given) {
      return {
        ...given,
        borderColor: getVar("textHighlightColor"),
        borderRadius: "0px",
        borderWidth: "1px",
        cursor: "pointer",
        fontSize,
      }
    },
    option(given, state) {
      const { isFocused, isSelected } = state

      return {
        ...given,
        cursor: "inherit",
        backgroundColor: isSelected
          ? getVar("primaryColor")
          : isFocused
            ? getVar("primaryHighlightColor")
            : "transparent",
        color: isSelected ? "white" : "inherit",
        "&:active": {
          color: "white",
          backgroundColor: getVar("primaryColor"),
        },
      }
    },
    clearIndicator(given) {
      return {
        ...given,
        "& svg:hover": {
          fill: getVar("badColor"),
        },
      }
    },
    indicatorSeparator() {
      return { display: "none" }
    },
    indicatorsContainer(given) {
      return {
        ...given,
        "& svg": {
          "&:hover": {
            fill: getVar("primaryColor"),
            cursor: "pointer",
          },
        },
      }
    },
    dropdownIndicator(given, state) {
      const { hasValue, isFocused } = state

      return {
        ...given,
        display: hasValue ? "none" : "block",
        color: isFocused ? getVar("primaryHighlightColor") : getVar("textHighlightColor"),
        "&:hover": {
          color: getVar("primaryColor"),
          cursor: "pointer",
        },
      }
    },
    multiValue(given) {
      return {
        ...given,
        borderRadius: "0px",
      }
    },
    multiValueRemove(given) {
      return {
        ...given,
        borderRadius: "0px",

        "& svg": {
          fill: getVar("textColor"),
        },

        "&:hover": {
          cursor: "pointer",
          backgroundColor: getVar("badHighlightColor"),
          "& svg": {
            fill: getVar("badColor"),
          },
        },
      }
    },
  }

  const newStyles = { ...thisStyles }

  Object.entries(styles).forEach(([key, styleFn]) => {
    if (isFn(styleFn)) {
      newStyles[key] = (given, state) => styleFn(thisStyles[key]?.(given, state) ?? given, state)
    }
  })

  return <Select styles={newStyles} {...restProps} />
}
