import { HeaderValue, rowSchema, RowValue } from "./schema"
import { isArray } from "~/src/lib/any"
import { validate } from "~/src/lib/object"
import yup from "~/src/lib/yup-extended"

export interface ValidationError extends yup.ValidationError {
  path: string
}

export class Validations {
  columns: HeaderValue["columns"]
  schema: any

  constructor(columns: HeaderValue["columns"], formats: HeaderValue["formats"]) {
    this.columns = columns
    this.schema = rowSchema(columns, formats)
  }

  validateRow(row: RowValue): Record<HeaderValue["columns"][number]["key"], ValidationError[]> | null {
    const testRow = {}
    const invalidRow = {}
    this.columns.map((column) => (testRow[column.key] = row[column.key]))

    const errorList = validate(this.schema, testRow)
    if (errorList.length === 0) {
      return null
    } else {
      errorList.forEach((error: yup.ValidationError) => {
        if (error.path) {
          if (error.path.includes(".")) {
            // This correction is required in case the column name contains a dot "." character.
            // This is because of yup behaviour that interprets string with dots as a path.
            // See https://github.com/jquense/yup/issues/1316.
            error.message = error.message.replace(error.path, "")
            error.errors = [error.message]
            error.path = JSON.parse(error.path)[0]
          }
          if (isArray(invalidRow[error.path!])) {
            invalidRow[error.path!].push(error)
          } else {
            invalidRow[error.path!] = [error]
          }
        }
      })
    }

    return invalidRow
  }
}
