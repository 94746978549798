import React, { useState, type ReactNode } from "react"

import { PlusIcon } from "@heroicons/react/24/outline"
import { sumBy } from "lodash-es"
import { twMerge } from "tailwind-merge"

import PriceQuantityFields, { FormPriceQuantity } from "./PriceQuantityFields"
import { PathBuilder } from "~/src/lib/iname"
import { PriceGroupDiscountCode } from "~/src/serializedRecords/priceGroupDiscountCode"

export type PriceQuantitiesTableProps = {
  baseIname: PathBuilder
  minimumMargin: number
  group: { priceQuantities: FormPriceQuantity[] }
  priceGroupDiscountCodes: PriceGroupDiscountCode[]
  netSetupPrice: number
}

export default function PriceQuantitiesTable({
  baseIname,
  minimumMargin,
  group,
  priceGroupDiscountCodes,
  netSetupPrice,
}: PriceQuantitiesTableProps) {
  const [priceQuantities, setPriceQuantities] = useState(group.priceQuantities)

  const defaultSupplierPriceDiscount = 0.5
  const defaultRunChargeDiscount = 0.2

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault()
    setPriceQuantities([
      ...priceQuantities,
      {
        supplierPrice: 0,
        discount: defaultSupplierPriceDiscount,
        runChargePrice: 0,
        runChargeDiscount: defaultRunChargeDiscount,
        minQuantityThreshold: 1,
        profitMarginPct: null,
        _destroy: false,
        decorationPrice: null,
      },
    ])
  }

  const onDeletePriceQuantities = (priceQuantities: FormPriceQuantity) => {
    setPriceQuantities((prevPriceQuantities) =>
      prevPriceQuantities
        .filter((pq) => pq !== priceQuantities || pq.id)
        .map((pq) => (pq === priceQuantities ? { ...pq, _destroy: true } : pq))
    )
  }

  type HeaderDefinition = {
    header: string | ReactNode
    headerClass: string
  }

  type HeaderGroup = HeaderDefinition[]

  const headerGroups: HeaderGroup[] = [
    [{ header: "Low quantity", headerClass: "text-left" }],
    [
      { header: "Unit price", headerClass: "text-right" },
      { header: "Discount code", headerClass: "text-left" },
      { header: "Net unit cost", headerClass: "text-right" },
    ],
    [
      { header: "Run charge", headerClass: "text-right" },
      { header: "Discount code", headerClass: "text-left" },
      { header: "Net run charge", headerClass: "text-right" },
    ],
    [
      { header: "Profit margin", headerClass: "text-right" },
      {
        header: (
          <span>
            Third-party
            <br />
            decoration method
          </span>
        ),
        headerClass: "text-right",
      },
      { header: "Display price", headerClass: "text-right" },
    ],
  ]

  return (
    <div className="mb-4">
      <table className="w-auto max-w-full border-separate border-spacing-0 text-sm">
        <thead>
          <tr className="h-14 bg-gray-100 px-4">
            {headerGroups.flatMap((headers, groupIndex) =>
              headers.map(({ header, headerClass }, headerIndex) => {
                const className = twMerge(
                  "text-wrap border-y border-gray-300 px-4 py-2 align-middle font-medium first:rounded-tl-lg",
                  headerIndex === 0 ? "border-l" : "",
                  headerClass
                )
                return (
                  <th key={`${groupIndex}-${headerIndex}`} className={className}>
                    {header}
                  </th>
                )
              })
            )}
            <th className="border border-gray-300 last:rounded-tr-lg">{/* Actions */}</th>
          </tr>
        </thead>
        <tbody>
          {priceQuantities.map((pq, index) => (
            <PriceQuantityFields
              baseIname={baseIname[index]}
              minimumMargin={minimumMargin}
              key={index}
              priceQuantity={pq}
              priceGroupDiscountCodes={priceGroupDiscountCodes}
              netSetupPrice={netSetupPrice}
              onDeletePriceQuantities={() => onDeletePriceQuantities(pq)}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={sumBy(headerGroups, "length") + 1}
              className="cursor-pointer rounded-b-lg border-b border-l border-r border-gray-300 py-2 text-center"
              onClick={handleAddRow}
            >
              <div className="flex flex-row items-center justify-center gap-3">
                <PlusIcon className="h-4 w-4" />
                <span className="font-medium">Add Line</span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
