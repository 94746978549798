import { twMerge } from "tailwind-merge"

export type ButtonKind = "primary" | "secondary" | "text"

const BORDERED_BUTTON_KINDS: Array<ButtonKind> = ["primary", "secondary"]

export function buttonClassName(kind: ButtonKind) {
  return twMerge(
    BORDERED_BUTTON_KINDS.includes(kind) && [
      "inline-block",
      "px-4",
      // Remove border width from padding to get that pixel perfect look
      "py-[calc(theme(spacing.2)-theme(borderWidth.DEFAULT))]",
      "rounded-lg",
      "font-medium",
      "text-center",
      "whitespace-nowrap",
      "border",
    ],
    kind === "primary" && [
      "border-navy-800",
      "disabled:border-gray-200",
      "bg-navy-800",
      "disabled:bg-gray-200",
      "text-white",
    ],
    kind === "secondary" && [
      "border-gray-900",
      "disabled:border-gray-200",
      "bg-transparent",
      "text-gray-900",
      "disabled:text-gray-200",
    ],
    kind === "text" && ["text-gray-500", "text-xs", "font-normal", "underline", "leading-none"]
  )
}

export function flashClassName(kind: "success" | "error") {
  return twMerge(
    ["flex", "flex-col", "p-4", "rounded-lg"],
    kind === "success" ? ["bg-emerald-400", "text-white"] : ["bg-red-100", "text-red-600"]
  )
}
