import React, { useState, type ReactNode } from "react"

import {
  DialogPanel,
  Description as HUIDescription,
  Dialog as HUIDialog,
  DialogTitle as HUIDialogTitle,
  useClose,
} from "@headlessui/react"
import type { DialogProps as HUIDialogProps } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { Elliptick } from "../Elliptick"
import { Ada } from "~/src/components/AdaKit"
import { Awaitable, isFn } from "~/src/lib/any"

type ConfirmModalProps = HUIDialogProps & {
  className?: string
  onAccept?: () => Awaitable<void>
  title?: string
  waitForever?: boolean
  okBtn?: { kind?: Ada.ButtonKind; text?: string }
  cancelBtn?: { kind?: Ada.ButtonKind; text?: string }
  children?: ReactNode
}

type ModalProps = {
  open: boolean
  onClose: () => void
  children: ReactNode
  className?: string
}

type DialogProps = HUIDialogProps & {
  className?: string
}

function waitForElmById(id: string): Promise<HTMLElement> {
  return new Promise((resolve) => {
    let node = document.getElementById(id)
    if (node) {
      return resolve(node)
    }

    const observer = new MutationObserver((_mutations) => {
      let node = document.getElementById(id)
      if (node) {
        observer.disconnect()
        resolve(node)
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

/**
 * Dialog wrapper to force tailwind-scope on the generated portal
 * this is needed as the portal is added to the bottom of the body tag outside of any tailwind-scope
 * if and when we remove the need for scoping this can be removed.
 */
function Dialog({ open, children, className, ...props }: DialogProps) {
  // every time the dialog is opened we need to add the tailwind-scope class to the portal, this is to prevent the
  // dialog from not being able to "open" as the portal is outside of the tailwind-scope
  React.useEffect(() => {
    if (open) {
      waitForElmById("headlessui-portal-root").then((node) => {
        node.classList.add("tailwind-scope")
      })
    }
  }, [open])

  return (
    <HUIDialog className="relative z-[100]" {...props} open={open}>
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          className={twMerge(
            "flex max-h-[50rem] max-w-4xl flex-col gap-4 overflow-y-auto rounded-lg border bg-white p-4",
            className
          )}
        >
          {children}
        </DialogPanel>
      </div>
    </HUIDialog>
  )
}

/**
 * ConfirmModal component based on @headless_ui/dialog to mimic, within reason, ActiveAdmin's modals.
 * @param props
 */
export function ConfirmModal(props: ConfirmModalProps) {
  const {
    title = "Are you sure?",
    onAccept,
    className,
    children,
    onClose,
    open,
    waitForever = false,
    okBtn = { text: "Ok" },
    cancelBtn = { text: "Cancel" },
    ...restProps
  } = props

  const [isProcessing, setIsProcessing] = useState(false)
  const handleOkClick = async () => {
    if (!isFn(onAccept)) return

    setIsProcessing(true)
    try {
      await onAccept()
    } catch (error) {
      console.error(error.message)
    } finally {
      if (!waitForever) setIsProcessing(false)
    }
  }

  return (
    <Dialog open={open} className={className} onClose={onClose} {...restProps}>
      <DialogTitle>{title}</DialogTitle>

      <DialogDescription>
        <div className="text-sm text-gray-500">{children}</div>
        <div className="-mb-4 mt-2 min-h-6 italic text-gray-600">
          {isProcessing ? (
            <span>
              Processing. Please wait
              <Elliptick />
            </span>
          ) : null}
        </div>
      </DialogDescription>
      <div className="flex justify-end gap-4">
        {cancelBtn ? (
          <Ada.Button kind={cancelBtn.kind ?? "secondary"} onClick={() => onClose(false)}>
            {cancelBtn.text}
          </Ada.Button>
        ) : null}
        {okBtn ? (
          <Ada.Button kind={okBtn.kind ?? "primary"} onClick={handleOkClick} disabled={isProcessing}>
            {okBtn.text}
          </Ada.Button>
        ) : null}
      </div>
    </Dialog>
  )
}

export function Modal(props: ModalProps) {
  const { open, onClose, children, className } = props

  return (
    <Dialog className={className} open={open} onClose={onClose}>
      {children}
    </Dialog>
  )
}

type WrapperProps = {
  className?: string
  children: React.ReactNode
}

export function CloseButton({ className, children }: WrapperProps) {
  const close = useClose()

  return (
    <button type="button" className={className} onClick={() => close()}>
      {children}
    </button>
  )
}

export function XCloseButton() {
  return (
    <CloseButton>
      <XMarkIcon className="h-6 w-6 text-gray-600" />
    </CloseButton>
  )
}

export function DialogTitle({ children, className }: WrapperProps) {
  return <HUIDialogTitle className={twMerge("text-lg font-bold", className)}>{children}</HUIDialogTitle>
}

export function DialogDescription({ children, className }: WrapperProps) {
  return (
    <HUIDescription as="section" className={twMerge("", className)}>
      {children}
    </HUIDescription>
  )
}
