import React from "react"

import { TrashIcon } from "@heroicons/react/24/outline"
import AsyncSelect from "react-select/async"

import { Ada } from "~/src/components/AdaKit"
import { adaClient } from "~/src/lib/appClients"
import { PathBuilder } from "~/src/lib/iname"
import { DecorationPriceGroup } from "~/src/serializedRecords/decorationPriceGroup"

export type FormDecorationPriceGroup = DecorationPriceGroup & {
  _destroy?: boolean
}

interface SelectOption {
  value: number
  label: string
}

interface ThirdPartyDecorationSelectorProps {
  baseIname: PathBuilder
  decorationPriceGroups: FormDecorationPriceGroup[]
  handleAddGroup: () => void
  handleRemoveGroup: (index: number) => void
  handleChange: (index: number, selectedOption: SelectOption | null) => void
}

export default function ThirdPartyDecorationSelector({
  baseIname,
  decorationPriceGroups,
  handleAddGroup,
  handleRemoveGroup,
  handleChange,
}: ThirdPartyDecorationSelectorProps) {
  return (
    <div className="mb-2 flex flex-row items-start justify-start gap-4">
      <label className="flex h-11 w-40 flex-col pt-2 text-sm leading-tight">
        <span className="block font-medium text-gray-900">Third-party decoration</span>
        <span className="block font-normal text-gray-500">optional</span>
      </label>

      <div className="flex flex-auto flex-col items-start justify-center gap-2">
        {decorationPriceGroups.map((linkedGroup, index) => (
          <div className="w-full" key={index}>
            <ThirdPartyDecoration
              baseIname={baseIname.linkedPriceGroupsAttributes[index]}
              index={index}
              linkedGroup={linkedGroup}
              handleRemoveGroup={handleRemoveGroup}
              handleChange={handleChange}
              fetchLinkedPriceGroups={fetchLinkedPriceGroups}
            />
          </div>
        ))}

        <Ada.Button kind="text" type="button" onClick={handleAddGroup}>
          + Add another third-party decoration
        </Ada.Button>
      </div>
    </div>
  )
}

interface ThirdPartyDecorationProps {
  baseIname: PathBuilder
  index: number
  linkedGroup: FormDecorationPriceGroup
  handleRemoveGroup: (index: number) => void
  handleChange: (index: number, selectedOption: { value: number; label: string } | null) => void
  fetchLinkedPriceGroups: (inputValue: string) => Promise<{ value: number; label: string }[]>
}

function ThirdPartyDecoration({
  baseIname,
  index,
  linkedGroup,
  handleRemoveGroup,
  handleChange,
  fetchLinkedPriceGroups,
}: ThirdPartyDecorationProps) {
  return (
    <>
      {/* Always include _destroy field to ensure Rails processes deletions */}
      <input type="hidden" name={baseIname.id.toString()} value={linkedGroup.id} />
      <input type="hidden" name={baseIname._destroy.toString()} value={linkedGroup._destroy ? "1" : "0"} />

      {!linkedGroup._destroy && (
        <div className="mt-1 flex items-center space-x-2">
          <AsyncSelect
            defaultOptions
            name={baseIname.linkedGroupId.toString()}
            loadOptions={fetchLinkedPriceGroups}
            onChange={(selectedOption) => handleChange(index, selectedOption)}
            value={linkedGroup.decorationId ? { value: linkedGroup.decorationId, label: linkedGroup.name } : null}
            placeholder="Search for a decoration..."
            className="w-full rounded-md border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />

          <button
            className="inline-block h-4 w-4 text-gray-500"
            title="Remove"
            onClick={() => handleRemoveGroup(index)}
          >
            <TrashIcon />
            &nbsp;
          </button>
        </div>
      )}
    </>
  )
}

async function fetchLinkedPriceGroups(inputValue: string): Promise<SelectOption[]> {
  if (!inputValue.trim()) return []

  try {
    const response = await adaClient.get("/price_structures/api_linked_product_group", {
      params: {
        term: inputValue,
        _type: "query",
        q: inputValue,
        limit: 100,
      },
    })

    const { results } = response.data
    if (!Array.isArray(results)) {
      console.error("Unexpected API response format:", response.data)
      return []
    }

    return results.map((group: { id: number; text: string }) => ({
      value: group.id,
      label: group.text,
    }))
  } catch (error) {
    console.error("Error fetching linked price groups:", error)
    return []
  }
}
