import React, { forwardRef, useCallback, useMemo, useState } from "react"

import { DocumentDuplicateIcon, TrashIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { ConfirmModal } from "../AdaKit/Modal"
import { Button } from "../BeeKit/Button"
import { Toggle } from "../BeeKit/Toggle"
import PriceQuantitiesTable from "./PriceQuantitiesTable"
import { FormPriceQuantity } from "./PriceQuantityFields"
import { SectionHeader } from "./SectionHeader"
import { SetupPricingTable } from "./SetupPricingTable"
import ThirdPartyDecorationSelector from "./ThirdPartyDecorationSelector"
import { PathBuilder } from "~/src/lib/iname"
import { PriceGroup } from "~/src/serializedRecords"
import { PriceGroupDiscountCode } from "~/src/serializedRecords/priceGroupDiscountCode"

export type FormPriceGroup = Omit<PriceGroup, "priceQuantities"> & {
  priceQuantities: FormPriceQuantity[]
  _destroy?: boolean
}

type PriceGroupFieldsProps = {
  minimumMargin: number
  group: FormPriceGroup
  baseIname: PathBuilder
  discountCodes: PriceGroupDiscountCode[]
  onDeletePriceGroup: () => void
  onDuplicatePriceGroup: () => void
  onToggleDefault: () => void
}

export const PriceGroupFields: React.FC<PriceGroupFieldsProps> = ({
  minimumMargin,
  group,
  baseIname,
  discountCodes,
  onDeletePriceGroup,
  onDuplicatePriceGroup,
  onToggleDefault,
}) => {
  const [decorationPriceGroups, setDecorationPriceGroups] = useState(
    group.decorationPriceGroups.length > 0 ? group.decorationPriceGroups : [{ decorationId: undefined, name: "" }]
  )
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedDiscountCode, setSelectedDiscountCode] = useState<PriceGroupDiscountCode>(() => {
    const groupDiscountCode = discountCodes.find((code) => group.setupDiscount === code.discount)

    return groupDiscountCode || discountCodes.find((code) => code.discount === 0) || discountCodes[0]
  })
  const [setupPrice] = useState(group.setupPrice || 0)
  const netSetup = selectedDiscountCode ? setupPrice * (1 - selectedDiscountCode.discount) : setupPrice

  // We use the discount as a starting point for what our margin is. We want a minimum margin of the global margin
  // though. If the discount is less, lock it to the global margin.
  const margin =
    selectedDiscountCode && selectedDiscountCode.discount < minimumMargin
      ? minimumMargin
      : selectedDiscountCode.discount
  const markedUpNetSetup = netSetup / (1 - margin)
  const netSetupValue = isNaN(netSetup) ? 0 : netSetup
  const markedUpNetSetupValue = isNaN(markedUpNetSetup) ? 0 : markedUpNetSetup

  const handleAddGroup = () => {
    setDecorationPriceGroups([...decorationPriceGroups, { decorationId: undefined, name: "" }])
  }

  const handleRemoveGroup = (index: number) => {
    setDecorationPriceGroups((prevGroups) =>
      prevGroups.map((group, i) => (i === index ? { ...group, _destroy: "1" } : group))
    )
  }

  const handleChangeGroup = (index: number, selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      const updatedGroups = [...decorationPriceGroups]
      updatedGroups[index] = { decorationId: selectedOption.value, name: selectedOption.label }
      setDecorationPriceGroups(updatedGroups)
    }
  }

  const handleConfirmDelete = useCallback(() => {
    setDeleteModalOpen(false)
    onDeletePriceGroup()
  }, [onDeletePriceGroup])

  const confirmDeleteText = useMemo(() => {
    if (group.id) {
      return (
        "Deleting this price group will hide it from future lookbooks but won't impact any orders it has been " +
        "attached to previously. This action cannot be undone."
      )
    } else {
      return "Are you sure you want to delete this price group? This action cannot be undone."
    }
  }, [group.id])

  return (
    <>
      <div className="mb-4 flex flex-col gap-10 rounded-md border border-gray-200 pb-12 pl-4 pr-4 pt-6">
        <section>
          <SectionHeader title="Price Group">
            <IconButton
              icon={<DocumentDuplicateIcon className="size-4" />}
              text="Duplicate Group"
              onClick={onDuplicatePriceGroup}
            />
            <IconButton
              icon={<TrashIcon className="size-4" />}
              text="Delete Group"
              className="text-red-600"
              onClick={() => setDeleteModalOpen(true)}
            />
            <ConfirmModal
              title="Delete price group?"
              okBtn={{ kind: "danger", text: "Delete" }}
              open={isDeleteModalOpen}
              onAccept={handleConfirmDelete}
              onClose={() => setDeleteModalOpen(false)}
            >
              {confirmDeleteText}
            </ConfirmModal>
          </SectionHeader>
          <div className="flex flex-col gap-6">
            <label className="mb-2 flex flex-row items-start justify-start gap-4">
              <div className="flex h-11 w-40 items-center text-sm font-medium leading-tight text-gray-900">
                <span className="block">Decoration method</span>
              </div>
              <div className="flex flex-auto flex-col items-start justify-center gap-2">
                <input
                  type="text"
                  name={baseIname.name.toString()}
                  defaultValue={group.name}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                <p className="mt-1 text-sm text-gray-500">This name is visible to the client</p>
              </div>
            </label>

            <ThirdPartyDecorationSelector
              baseIname={baseIname}
              decorationPriceGroups={decorationPriceGroups}
              handleAddGroup={handleAddGroup}
              handleRemoveGroup={handleRemoveGroup}
              handleChange={handleChangeGroup}
            />

            <div className="flex items-center space-x-6">
              <div className="flex items-center gap-2 space-x-2">
                <Toggle
                  id={baseIname.default.toString()}
                  name={baseIname.default.toString()}
                  defaultChecked={group.default}
                  key={group.default ? "checked" : "unchecked"} // Add a dynamic key to force re-render
                  onChange={onToggleDefault}
                />
                <label htmlFor={baseIname.default.toString()} className="text-sm text-gray-900">
                  Set as default price group
                </label>
              </div>
            </div>
          </div>
        </section>

        <section>
          <SectionHeader title="Setup price" />
          <SetupPricingTable
            baseIname={baseIname}
            discountCodes={discountCodes}
            margin={margin}
            netSetupCost={netSetupValue}
            netSetupPrice={markedUpNetSetupValue}
            selectedDiscountCode={selectedDiscountCode}
            setupPrice={setupPrice}
            onDiscountCodeChange={setSelectedDiscountCode}
          />
        </section>

        <section className="mb-4">
          <SectionHeader title="Unit price" />
          <PriceQuantitiesTable
            baseIname={baseIname.priceQuantitiesAttributes}
            minimumMargin={minimumMargin}
            group={group}
            priceGroupDiscountCodes={discountCodes}
            netSetupPrice={markedUpNetSetupValue}
          />
        </section>

        {!group.id ? null : <input type="hidden" name={baseIname.id.toString()} value={group.id} />}
      </div>
    </>
  )
}

export type IconButtonProps = {
  icon: JSX.Element
  text: string
  className?: string | undefined
} & React.ButtonHTMLAttributes<HTMLButtonElement>

/**
 * IconButton component, a generic button with an icon and text.
 * Uses a Button component under the hood with forwardRef to propagate props.
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, text, className = "text-gray-900", ...restProps }, ref) => {
    const hoverClass = className.includes("text-red") ? "hover:bg-red-100" : "hover:bg-gray-100"
    return (
      <Button
        ref={ref}
        className={twMerge(
          `flex items-center space-x-2 border-gray-200 bg-white px-3 py-2 text-sm font-medium shadow-none ${hoverClass}`,
          className
        )}
        {...restProps}
      >
        {icon}
        <span>{text}</span>
      </Button>
    )
  }
)
IconButton.displayName = "IconButton"
