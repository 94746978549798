import React, { forwardRef } from "react"

import { Link, LinkProps } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { buttonClassName, ButtonKind } from "./sharedClassNames"

export type ButtonLinkProps = { kind?: ButtonKind } & LinkProps

/**
 * A Bee styled react-router `Link` that looks like a button.
 */
export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(props, ref) {
  const { className, kind = "primary", ...restProps } = props

  return <Link className={twMerge(buttonClassName(kind), className)} ref={ref} {...restProps} />
})
