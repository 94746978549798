import { memoize } from "lodash-es"

import { countries, CountryData } from "~/src/data"
import { isBlank, isNil, isntNil } from "~/src/lib/any"

const PRIORITY_ALPHA2S = ["US", "CA", "GB", "FR"]
const DISABLED_COUNTRIES = ["AR", "IL", "TR"]

const FEDERAL_TAX_ID_NAME_BY_COUNTRY = {
  AR: "Consumer Tax ID (CUIL)",
  BR: "CPF Number",
  CL: "Consumer Tax ID (RUN)",
  CN: "National ID Card Number",
  ID: "NPWP Number",
  IN: "PAN",
  KE: "KRA PIN",
  KR: "PCCC",
  MX: "RFC Number",
  SA: "National ID Number or Iqamaa",
  TW: "National ID Card Number",
  VN: "Personal Identity Card Number",
}

export class Country {
  // Memoize some values
  static __zipPattern: Record<string, RegExp> = {}
  static __stateCodes: Record<string, Set<string>> = {}
  static __all?: Country[]
  static __allPriorityFirst?: Country[]

  static disabledCountries() {
    return DISABLED_COUNTRIES
  }

  static federalTaxIdCountries() {
    return Object.keys(FEDERAL_TAX_ID_NAME_BY_COUNTRY)
  }

  static federalTaxIdNameByCountry() {
    return FEDERAL_TAX_ID_NAME_BY_COUNTRY
  }

  static fromAlpha2(rawCode: string) {
    const code = (rawCode ?? "")?.trim()?.toUpperCase()
    const country = countries[code]

    if (isBlank(country)) return new TypeError("Invalid ISO 3166-1 alpha-2 country code")

    return new this(country)
  }

  static all() {
    if (isNil(this.__all)) {
      this.__all = Object.values(countries).map((c) => new this(c))
    }

    return this.__all
  }

  static allPriorityFirst() {
    if (isNil(this.__allPriorityFirst)) {
      const priorityA2s = new Set(PRIORITY_ALPHA2S)

      this.__allPriorityFirst = Array.from(priorityA2s).map((a2) => new this(countries[a2]))

      const remainingCountries: Country[] = []

      for (const [alpha2, countryData] of Object.entries(countries)) {
        if (priorityA2s.has(alpha2)) continue
        remainingCountries.push(new this(countryData))
      }

      this.__allPriorityFirst.push(...remainingCountries.sort((a, b) => a.name.localeCompare(b.name)))
    }

    return this.__allPriorityFirst
  }

  /**
   * Search countries by alpha2s, and sort with priority countries first.
   */
  static whereAlpha2 = memoize((alpha2s: string[] = []) => {
    const countries: Country[] = []

    for (const country of alpha2s.map((alpha2) => Country.fromAlpha2(alpha2))) {
      if (country instanceof Country) countries.push(country)
    }

    return countries
  })

  __data: CountryData

  constructor(country: CountryData) {
    this.__data = country
  }

  get name() {
    return this.__data.name
  }

  get alpha2() {
    return this.__data.alpha2
  }

  get zipFormat() {
    return this.__data.zipFormat
  }
  get states() {
    return this.__data.states
  }

  zipPattern() {
    if (!this.isZipRequired() || isNil(this.zipFormat)) return

    if (isNil(Country.__zipPattern[this.alpha2])) {
      Country.__zipPattern[this.alpha2] = new RegExp(
        "^(" +
          this.zipFormat
            .trim()
            .split(/\s*,\s*/)
            .map((s) =>
              s
                .replace(/n+|a+|cc|[-]|#+/gi, (m) => {
                  const match = m.toLowerCase()

                  if (match.startsWith("n")) {
                    return `[0-9]{${m.length}}`
                  } else if (match.startsWith("a")) {
                    return `[a-z]{${m.length}}`
                  } else if (match === "cc") {
                    return this.alpha2
                  } else if (match === "-") {
                    return match + "?"
                  } else {
                    return `[a-z0-9]{0,${m.length}}`
                  }
                })
                .replace(/\s+/gi, () => `\\s*`)
            )
            .join("|") +
          ")$",
        "i"
      )
    }

    return Country.__zipPattern[this.alpha2]
  }

  isZipRequired() {
    if (isntNil(this.__data.isZipRequired)) {
      return this.__data.isZipRequired
    }

    return typeof this.zipFormat === "string"
  }

  isStateRequired() {
    return this.__data.isStateRequired ?? false
  }

  isInternational() {
    return this.alpha2 !== "US"
  }

  stateCodes() {
    if (isNil(this.states)) return

    if (isNil(Country.__stateCodes[this.alpha2])) {
      Country.__stateCodes[this.alpha2] = new Set(this.states.map((s) => s.alpha2))
    }

    return Country.__stateCodes[this.alpha2]
  }
}
