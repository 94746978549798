import React, { useEffect, useState } from "react"

import { CubeIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

export type ImageProps = {
  src?: string
  className?: string
  fallbackClassName?: string
  children?: React.ReactNode
  onFinal?: () => void // Called when the image is loaded, errored or will never load (src is nil)
} & JSX.IntrinsicElements["img"]

const DefaultFallback = ({ className }: { className?: string }) => (
  <div className={twMerge("flex items-center justify-center rounded-lg bg-gray-100 text-gray-400", className)}>
    <CubeIcon className="h-10 w-10 stroke-1" />
  </div>
)

/**
 * Thin wrapper component around `img` with fallback capabilities.
 *
 * @param props - The image component props.
 * @returns The rendered image component.
 */
export function Image(props: ImageProps) {
  const { src, onFinal, children, fallbackClassName, ...restProps } = props
  const [hasError, setHasError] = useState<boolean>(false)

  const onError = () => {
    setHasError(true)
    onFinal?.()
  }

  useEffect(() => {
    if (src == null) {
      requestAnimationFrame(() => onFinal?.())
    }
  }, [src])

  return (
    <div className="contents">
      {src && !hasError ? (
        <img src={src} onError={onError} onLoad={() => onFinal?.()} {...restProps} />
      ) : children ? (
        <>{children}</>
      ) : (
        <DefaultFallback className={fallbackClassName} />
      )}
    </div>
  )
}
