import * as React from "react"
import { useEffect, useMemo } from "react"

import { FulfillmentContext } from "./FulfillmentContext"
import { AutoAllocateField } from "./FulfillmentFields/AutoAllocateField"
import { FulfillmentMethodSection, FulfillmentRequestDraft } from "./FulfillmentFields/FulfillmentMethodSection"
import { FulfillmentContextFields, FulfillmentMethod, PreferenceCollectorErrorContext } from "./types"

type FulfillmentFormSectionProps = {
  errors: PreferenceCollectorErrorContext
  fulfillmentRequestDraft: FulfillmentRequestDraft
  initialValues: FulfillmentContextFields
}

const fulfillLaterOptions: string[] = [FulfillmentMethod.LegacyFulfillLater, FulfillmentMethod.FulfillLater]

export function FulfillmentFormSection({
  errors,
  initialValues,
  fulfillmentRequestDraft,
}: FulfillmentFormSectionProps) {
  const isLegacy = useMemo(
    () => initialValues.fulfillmentMethod === FulfillmentMethod.LegacyFulfillLater,
    [initialValues.fulfillmentMethod]
  )
  const [fulfillmentMethod, setFulfillmentMethod] = React.useState(initialValues.fulfillmentMethod)
  const [autoAllocate, setAutoAllocate] = React.useState(initialValues.autoAllocate)
  const { estimatedRecipientCount, targetShipDate } = initialValues

  useEffect(() => {
    if (!autoAllocate && !fulfillLaterOptions.includes(fulfillmentMethod)) {
      setFulfillmentMethod(isLegacy ? FulfillmentMethod.LegacyFulfillLater : FulfillmentMethod.FulfillLater)
    }
  }, [autoAllocate, fulfillmentMethod, isLegacy])

  return (
    <FulfillmentContext.Provider
      value={{
        autoAllocate,
        errors,
        estimatedRecipientCount,
        fulfillmentMethod,
        isLegacy,
        targetShipDate,
        setAutoAllocate,
        setFulfillmentMethod,
      }}
    >
      <ol>
        <AutoAllocateField
          fieldName="preference_collector[drip]"
          id="preference_collector_drip"
          error={errors["drip"]}
        />
        <FulfillmentMethodSection
          initialFulfillmentMethod={initialValues.fulfillmentMethod}
          fulfillmentRequestDraft={fulfillmentRequestDraft}
        />
      </ol>
    </FulfillmentContext.Provider>
  )
}
