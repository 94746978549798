import React, { type DetailedHTMLProps, type InputHTMLAttributes } from "react"

import { twMerge } from "tailwind-merge"

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
export type PercentInputProps = Omit<InputProps, "type" | "value" | "onChange"> & {
  className?: string
  percentageClassName?: string
  inputClassName?: string
  value?: number | null | undefined
  onChange?: (value: number | null, e: React.ChangeEvent<HTMLInputElement>) => void
}

export function PercentInput({
  className,
  percentageClassName,
  inputClassName,
  value,
  onChange,
  ...inputProps
}: PercentInputProps) {
  const containerClass = twMerge(
    "flex max-w-[8ch] flex-row items-center gap-2 rounded-md border border-gray-300 p-2 shadow-sm focus-within:border-blue-500 focus-within:ring focus-within:ring-blue-500",
    className
  )
  const inputClass = twMerge("flex-auto border-0 p-0 text-right text-sm focus:border-0 focus:ring-0", inputClassName)
  const percentClass = twMerge("flex-initial text-gray-700", percentageClassName)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    if (isNaN(value)) {
      onChange?.(null, e)
    } else {
      onChange?.(value, e)
    }
  }

  return (
    <div className={containerClass}>
      <input className={inputClass} type="text" value={value ?? ""} onChange={handleChange} {...inputProps} />
      <div className={percentClass}>%</div>
    </div>
  )
}

PercentInput.defaultProps = {
  min: 0,
  step: 1,
}
