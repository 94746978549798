import React, { useCallback, useState } from "react"

import CurrencyInput from "react-currency-input-field"

import { PathBuilder } from "~/src/lib/iname"
import { PriceGroupDiscountCode } from "~/src/serializedRecords/priceGroupDiscountCode"

export type SetupPricingTableProps = {
  baseIname: PathBuilder
  discountCodes: PriceGroupDiscountCode[]
  margin: number
  netSetupCost: number
  netSetupPrice: number
  selectedDiscountCode: PriceGroupDiscountCode
  setupPrice: number
  onDiscountCodeChange: (discountCode: PriceGroupDiscountCode) => void
}

const headers = ["Setup Price", "Discount Code", "Net Setup Cost", "Margin", "Net Setup Price"]

export function SetupPricingTable({
  baseIname,
  discountCodes,
  margin,
  netSetupCost,
  netSetupPrice,
  selectedDiscountCode,
  setupPrice,
  onDiscountCodeChange,
}: SetupPricingTableProps) {
  const [localSetupPrice, setLocalSetupPrice] = useState(setupPrice)
  const handleDiscountCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedCode = discountCodes.find(
        (discountCode) => discountCode.discount === parseFloat(event.target.value)
      )
      if (selectedCode) {
        onDiscountCodeChange(selectedCode)
      }
    },
    [discountCodes, onDiscountCodeChange]
  )

  return (
    <table className="mb-0 w-auto border-separate border-spacing-0 text-sm">
      <thead>
        <tr className="h-14 bg-gray-100 px-4">
          {headers.map((header, index) => (
            <th
              key={index}
              className="border-b border-l border-t border-gray-300 px-4 py-2 text-left align-middle font-medium first:rounded-tl-lg last:rounded-tr-lg last:border-r"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="group/row">
          <td className="border-b border-l px-2.5 py-2 text-right align-middle group-last/row:rounded-bl-lg">
            <CurrencyInput
              className="w-full max-w-[10ch] rounded-md border-gray-300 p-1 text-right text-sm"
              defaultValue={setupPrice}
              disableAbbreviations
              placeholder="0.00"
              prefix="$"
              onValueChange={(_value, _name, values) => setLocalSetupPrice(values?.float || 0)}
            />
            <input type="hidden" name={baseIname.setup.toString()} value={localSetupPrice} />
          </td>
          <td className="border-b border-l px-2.5 py-2 text-right align-middle">
            <select
              className="block max-w-[14ch] rounded-md border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:ring-blue-500"
              id={baseIname.setupDiscount.toString()}
              name={baseIname.setupDiscount.toString()}
              value={selectedDiscountCode?.discount ?? ""}
              onChange={handleDiscountCodeChange}
            >
              <option value="" disabled>
                Select a discount code
              </option>
              {discountCodes.map((discountCode) => (
                <option key={discountCode.code} value={discountCode.discount}>
                  {discountCode.label}
                </option>
              ))}
            </select>
          </td>
          <td className="border-b border-l px-2.5 py-2 text-right align-middle">${netSetupCost.toFixed(2)}</td>
          <td className="border-b border-l px-2.5 py-2 text-right align-middle">{(margin * 100).toFixed(0)}%</td>
          <td className="border-b border-l border-r px-2.5 py-2 text-right align-middle font-medium group-last/row:rounded-br-lg">
            ${netSetupPrice.toFixed(2)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
