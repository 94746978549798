import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { buttonClassName, ButtonKind } from "./sharedClassNames"

export type ButtonAnchorProps = { kind?: ButtonKind } & JSX.IntrinsicElements["a"]

export const ButtonAnchor = forwardRef<HTMLAnchorElement, ButtonAnchorProps>(function ButtonAnchor(props, ref) {
  const { className, kind = "primary", ...restProps } = props

  return <a className={twMerge(buttonClassName(kind), className)} ref={ref} {...restProps} />
})
