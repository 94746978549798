import React, { ReactNode } from "react"

export type SectionHeaderProps = {
  title: string
  children?: ReactNode
}

export function SectionHeader({ title, children }: SectionHeaderProps) {
  return (
    <div className="mb-4 flex h-11 items-center justify-between">
      <h3 className="flex-1 text-lg font-medium text-gray-400">{title}</h3>
      {children && <div className="flex h-full space-x-4">{children}</div>}
    </div>
  )
}
