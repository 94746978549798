import React, { useState } from "react"

import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline"
import CurrencyInput from "react-currency-input-field"

import { PercentInput } from "~/src/components/PriceStructure/PercentInput"
import { isNil, Nil } from "~/src/lib/any"
import { PathBuilder } from "~/src/lib/iname"
import { PriceGroupDiscountCode } from "~/src/serializedRecords/priceGroupDiscountCode"
import { PriceQuantity } from "~/src/serializedRecords/priceQuantity"

export type PriceQuantityFieldsProps = {
  baseIname: PathBuilder
  minimumMargin: number
  priceQuantity: FormPriceQuantity
  priceGroupDiscountCodes: PriceGroupDiscountCode[]
  netSetupPrice: number
  onDeletePriceQuantities: () => void
}

export type FormPriceQuantity = Omit<PriceQuantity, "decorationPrice"> & {
  _destroy: boolean
  decorationPrice: number | Nil
}

export default function PriceQuantityFields({
  baseIname,
  minimumMargin,
  priceQuantity,
  priceGroupDiscountCodes,
  netSetupPrice,
  onDeletePriceQuantities,
}: PriceQuantityFieldsProps) {
  const [localMinQuantity, setLocalMinQuantity] = useState(priceQuantity.minQuantityThreshold)
  const [localSupplierPrice, setLocalSupplierPrice] = useState(priceQuantity.supplierPrice)
  const [localDiscount, setLocalDiscount] = useState(priceQuantity.discount)
  const [localRunChargePrice, setLocalRunChargePrice] = useState(priceQuantity.runChargePrice)
  const [localRunChargeDiscount, setLocalRunChargeDiscount] = useState(priceQuantity.runChargeDiscount)
  const [localMarginPct, setLocalMarginPct] = useState(priceQuantity.profitMarginPct)

  const netUnitCost = localSupplierPrice * (1 - localDiscount)
  const netRunChargeCost = localRunChargePrice * (1 - localRunChargeDiscount)
  const netUnitSetupPrice = netSetupPrice / localMinQuantity

  const defaultMargin = localDiscount < minimumMargin ? minimumMargin : localDiscount
  const margin = localMarginPct ? localMarginPct * 0.01 : defaultMargin

  const decorationPrice = priceQuantity.decorationPrice

  const netUnitPrice = isNil(decorationPrice)
    ? null
    : (netUnitCost + netRunChargeCost) / (1 - margin) + decorationPrice + netUnitSetupPrice

  if (priceQuantity._destroy) {
    return (
      <tr className="hidden">
        <td>
          <input type="hidden" name={baseIname._destroy.toString()} value="1" />
          <input type="hidden" name={baseIname.id.toString()} value={priceQuantity.id} />
        </td>
      </tr>
    )
  }

  return (
    <tr className="border border-gray-300">
      <td className="border-b border-l border-gray-300 px-4 py-2 align-middle">
        <input
          name={baseIname.lowQuantity.toString()}
          type="number"
          value={localMinQuantity}
          onChange={(e) => setLocalMinQuantity(parseInt(e.target.value, 10) || 0)}
          className="w-full max-w-[8ch] rounded-md border-gray-300 px-1 py-2 text-center text-sm"
        />
      </td>
      <td className="border-b border-l border-gray-300 px-4 py-2 text-right align-middle">
        <CurrencyInput
          allowNegativeValue={false}
          className="w-full max-w-[10ch] rounded-md border-gray-300 p-2 text-right text-sm"
          defaultValue={localSupplierPrice}
          disableAbbreviations
          prefix="$"
          onValueChange={(_value, _name, values) => setLocalSupplierPrice(values?.float || 0)}
        />
        <input type="hidden" name={baseIname.supplierPrice.toString()} value={localSupplierPrice} />
      </td>
      <td className="border-b border-gray-300 px-4 py-2 align-middle">
        <select
          name={baseIname.discount.toString()}
          value={localDiscount}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setLocalDiscount(parseFloat(e.target.value))
          }}
          className="w-[14ch] rounded-md border-gray-300 p-2 text-sm"
        >
          <option value={""} disabled>
            Select a discount code
          </option>
          {priceGroupDiscountCodes.map((discountCode) => (
            <option key={discountCode.code} value={discountCode.discount}>
              {discountCode.label}
            </option>
          ))}
        </select>
      </td>
      <td className="border-b border-gray-300 px-4 py-2 text-right align-middle">${netUnitCost.toFixed(2)}</td>
      <td className="border-b border-l border-gray-300 px-4 py-2 text-right align-middle">
        <CurrencyInput
          allowNegativeValue={false}
          className="w-full max-w-[10ch] rounded-md border-gray-300 p-2 text-right text-sm"
          defaultValue={localRunChargePrice}
          disableAbbreviations
          prefix="$"
          onValueChange={(_value, _name, values) => setLocalRunChargePrice(values?.float || 0)}
        />
        <input type="hidden" name={baseIname.runChargePrice.toString()} value={localRunChargePrice} />
      </td>
      <td className="border-b border-gray-300 px-4 py-2 align-middle">
        <select
          name={baseIname.runChargeDiscount.toString()}
          value={localRunChargeDiscount}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setLocalRunChargeDiscount(parseFloat(e.target.value))
          }}
          className="w-[14ch] rounded-md border-gray-300 p-2 text-sm"
        >
          <option value={""} disabled>
            Select one
          </option>
          {priceGroupDiscountCodes.map((discountCode) => (
            <option key={discountCode.code} value={discountCode.discount}>
              {discountCode.label}
            </option>
          ))}
        </select>
      </td>
      <td className="border-b border-gray-300 px-4 py-2 text-right align-middle">${netRunChargeCost.toFixed(2)}</td>
      <td className="border-b border-l border-gray-300 px-4 py-2 text-right align-middle">
        <PercentInput
          name={baseIname.profitMarginPct.toString()}
          pattern="^(?:[1-9]?[0-9])$"
          placeholder={(defaultMargin * 100).toFixed(0)}
          value={localMarginPct == null ? null : Number(localMarginPct.toFixed(0))}
          onChange={(value) => setLocalMarginPct(value || null)}
        />
      </td>
      <td className="border-b border-gray-300 px-4 py-2 text-right align-middle">{nullableMoney(decorationPrice)}</td>
      <td className="border-b border-gray-300 px-4 py-2 text-right align-middle">
        <span className="inline-block rounded bg-navy-800 px-1 py-0.5 text-white">{nullableMoney(netUnitPrice)}</span>
      </td>
      <td
        className="cursor-pointer border-b border-l border-r border-gray-300 px-4 py-2 text-center align-middle"
        onClick={onDeletePriceQuantities}
      >
        <TrashIcon className="h-4 w-4" />
      </td>
      {!priceQuantity.id ? null : (
        <td className="hidden">
          <input type="hidden" name={baseIname.id.toString()} value={priceQuantity.id} />
        </td>
      )}
    </tr>
  )
}

const nullableMoney = (num: number | Nil) =>
  isNil(num) ? <ArrowPathIcon className="inline-block size-4" /> : `$${num.toFixed(2)}`
