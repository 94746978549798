import React, { useState } from "react"

import { Switch } from "@headlessui/react"
import { twMerge } from "tailwind-merge"

import { useFieldContext } from "./Field"
import { PathBuilder } from "~/src/lib/iname"
import { dataFlag } from "~/src/lib/jsx"

type ToggleProps_ = {
  id?: string
  name?: string | PathBuilder
  className?: string
  onChange?: (value: boolean) => void
}

export type ToggleProps =
  | ({
      defaultChecked?: boolean // Uncontrolled mode
      checked?: never
    } & ToggleProps_)
  | ({
      checked?: boolean // Controlled mode
      defaultChecked?: never
    } & ToggleProps_)

/**
 * A BeeKit toggle switch implemented with Headless UI's Switch. The component can be used in controlled or uncontrolled
 * mode. Set `checked` prop to control the state of the component. Only `checked` or `defaultChecked` can be set at a
 * time.
 * @param props
 * @param props.checked control the state of the component.
 * @param props.defaultChecked set the initial state of the component allowing it to be uncontrolled.
 */
export function Toggle(props: ToggleProps) {
  const { name, id, defaultChecked, className, onChange } = props
  const [checked_, setChecked] = useState(defaultChecked ?? false)

  const isControlled = "checked" in props
  const checked = isControlled ? props.checked : checked_
  const fieldContext = useFieldContext()

  const handleChange = (value: boolean) => {
    fieldContext?.descendentChange?.()
    if (!isControlled) setChecked(value)
    onChange?.(value)
  }

  return (
    <>
      {/* An hidden input is added to implement a behaviour that mimics how Rails' checkbox helpers behave. If we rely
      `Switch`'s hidden input, it will treat it as a checkbox meaning that the value will be set to "on", and the input
      will only be present when it is checked. */}
      {name != null ? <input id={id} name={name.toString()} type="hidden" value={checked ? "1" : "0"} /> : <></>}
      <Switch
        checked={checked}
        onChange={handleChange}
        className={twMerge(
          "relative",
          "inline-flex",
          "h-6",
          "w-11",
          "items-center",
          "rounded-full",
          "transition-colors",
          "bg-gray-100",
          "border",
          "border-gray-300",
          "focus:outline-none",
          "focus:ring-2",
          "focus:ring-offset-2",
          "focus:ring-blue-600",
          "data-[checked]:bg-navy-800",
          "data-[checked]:border-navy-800",
          className
        )}
        {...dataFlag(fieldContext?.hasErrors, "errored")}
        {...(checked && { "data-checked": "" })}
      >
        <span
          className={twMerge(
            "inline-block",
            "h-4",
            "w-4",
            "transform",
            "rounded-full",
            "bg-white",
            "transition-transform",
            "translate-x-1",
            checked && "translate-x-6"
          )}
        />
      </Switch>
    </>
  )
}
