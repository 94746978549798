import * as React from "react"

import { twMerge } from "tailwind-merge"

export type ButtonKind = "primary" | "secondary" | "danger" | "text"

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  kind: ButtonKind
}
type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean
  kind: ButtonKind
}

const buttonCommon = ["disabled:cursor-not-allowed", "shadow-none"]
const buttonLinkCommon = ["shadow-none"]

const borderedButtonCommon = [
  "border",
  "duration-150",
  "md:p-3",
  "min-w-20",
  "p-2",
  "rounded-lg",
  "text-center",
  "text-sm",
  "transition",
]

const borderedButtonLinkBase = [
  "border",
  "duration-150",
  "font-medium",
  "md:p-3",
  "p-2",
  "rounded-lg",
  "text-center",
  "text-sm",
  "transition",
]

const BORDERED_BUTTON_KINDS: Array<ButtonKind> = ["primary", "secondary", "danger"]

const disabledStyleClassNames = (kind: ButtonKind): Array<string> => {
  const classNames = ["cursor-not-allowed", "opacity-80"]

  switch (kind) {
    case "primary": {
      classNames.push("bg-gray-300")
      break
    }
    case "secondary": {
      classNames.push("stroke-gray-800", "text-gray-300")
      break
    }
    case "danger": {
      classNames.push("bg-red-300")
      break
    }
  }

  return classNames
}

const hoverStyleClassNames = (kind: ButtonKind): Array<string> => {
  const classNames: Array<string> = []

  switch (kind) {
    case "primary": {
      classNames.push("hover:bg-navy-900")
      break
    }
    case "secondary": {
      classNames.push("hover:border-gray-800", "hover:stroke-gray-800")
      break
    }
    case "danger": {
      classNames.push("hover:bg-red-700")
      break
    }
    case "text": {
      classNames.push("hover:text-gray-800")
    }
  }

  return classNames
}

const buttonLinkKindClassnames = (kind: ButtonKind): Array<string> => {
  switch (kind) {
    case "primary": {
      return ["text-white", "font-medium", "bg-navy-800", "focus:bg-navy-900", "focus:stroke-blueslate-100"]
    }
    case "secondary": {
      return ["border-gray-200", "stroke-gray-200", "text-gray-800"]
    }
    case "danger": {
      return ["bg-red-600", "text-white", "hover:bg-red-700", "focus:bg-red-700", "focus:stroke-blueslate-100"]
    }
    case "text": {
      return ["text-gray-500", "text-xs", "font-normal", "underline"]
    }
  }
}

const buttonKindClassnames = (kind: ButtonKind): Array<string> => {
  switch (kind) {
    case "primary": {
      return [
        "text-white",
        "font-medium",
        "bg-navy-800",
        "hover:bg-navy-900",
        "focus:bg-navy-900",
        "focus:stroke-blueslate-100",
        "disabled:bg-gray-300",
      ]
    }
    case "secondary": {
      return [
        "border-gray-200",
        "stroke-gray-200",
        "text-gray-800",
        "hover:border-gray-800",
        "hover:stroke-gray-800",
        "disabled:stroke-gray-800",
        "disabled:text-gray-300",
      ]
    }
    case "danger": {
      return [
        "bg-red-600",
        "text-white",
        "hover:bg-red-700",
        "focus:bg-red-700",
        "focus:stroke-blueslate-100",
        "disabled:bg-red-300",
      ]
    }
    case "text": {
      return [
        "text-gray-500",
        "text-xs",
        "font-normal",
        "underline",
        "hover:text-gray-800",
        "disabled:hover:text-gray-500",
        "disabled:opacity-70",
      ]
    }
  }
}

export function ButtonLink({ kind, className, disabled, href: originalHref, ...props }: LinkProps) {
  let href = originalHref

  if (disabled) {
    href = undefined
  }

  return (
    <a
      className={twMerge(
        buttonLinkCommon,
        BORDERED_BUTTON_KINDS.includes(kind) && borderedButtonLinkBase,
        buttonLinkKindClassnames(kind),
        className,
        !disabled && hoverStyleClassNames(kind),
        disabled && disabledStyleClassNames(kind)
      )}
      href={href}
      {...props}
    />
  )
}

export function Button({ className, kind, ...props }: ButtonProps) {
  return (
    <button
      className={twMerge(
        buttonCommon,
        BORDERED_BUTTON_KINDS.includes(kind) && borderedButtonCommon,
        buttonKindClassnames(kind),
        className
      )}
      {...props}
    />
  )
}
